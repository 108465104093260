import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import VideoPlayer from '../components/VideoPlayer';

//images
import BENTOHUB_LOGO from '../images/BENTOHUB_LOGO.png'

export default function VideoPlayerPage(){

	const { videoId, controls } = useParams();

	const customControls = {
		removeControls: controls? controls.includes('controls=1') : false,
		loop:  controls? controls.includes('loop=1') : false,
		autoplay: controls? controls.includes('autoplay=1') : false,
		muted: controls? controls.includes('muted=1') : false,
		startAt: controls && controls.includes('startAt')? controls.split("startAt=")[1] : false
	}

	const [videoData, setVideoData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [ isFramed, setIsFramed ] = useState(false)

	function hasControls(inputString) {
	  return controls.includes('controls=1');
	}

	useEffect(() => {
		setIsLoading(true);
		fetch(`${window.server}/videos/get-video`, {
		method: 'POST',
		headers: {
		  'Content-Type': 'application/json',
		},
		body: JSON.stringify({
		  videoId: videoId
		})
		})
		.then(response => {
		if (!response.ok) {
		  throw new Error(`HTTP error! status: ${response.status}`);
		}
		return response.json();
		})
		.then(data => {
			if(!data.error){
				setVideoData(data);
			}
		})
		.catch(error => {
			console.error('There was a problem with the fetch operation:', error);
		})
		.finally(() => {
			setIsLoading(false);
		});
	}, [videoId]);

	useEffect(() => {
	  if (window !== window.top) {
	    setIsFramed(true)
	  } else {
	    setIsFramed(false)
	  }
	}, []);

	if(videoData && (videoData.settings.visibility === "Private" || videoData.configurationData.visibility === "Private") && !isFramed && !isLoading){
		return(
			<div className="h-screen max-h-screen max-w-screen flex flex-col gap-4 justify-center items-center overflow-hidden">
				<img src={BENTOHUB_LOGO} className="h-16 w-auto "/>
				<h1 className="font-bold text-[24px]">403 Forbidden</h1>
			</div>
		)
	}

	if(!videoData && !isLoading){
		return(
			<div className="h-screen max-h-screen max-w-screen flex flex-col gap-4 justify-center items-center overflow-hidden">
				<img src={BENTOHUB_LOGO} className="h-16 w-auto "/>
				<h1 className="font-bold text-[24px]">403 Forbidden</h1>
			</div>
		)
	}

	return (

		<div className="max-h-screen max-w-screen overflow-hidden">
		    {
		    	videoData?
		    	<VideoPlayer 
		    	customControls={customControls}
		    	videoData={videoData}
		    	isLoading={isLoading}
		    	setIsLoading={setIsLoading}
		    	src={`${window.server}/videos/${videoId}${videoData.videoURL}`}
		    	timeChaptersData={videoData.timeChapter}
		    	/>
		    	:
		    	null
		    }
		</div>

	)

}