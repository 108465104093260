import { FadeLoader, ClipLoader } from 'react-spinners';

export default function LoadSpinner({ isLoading, type }){

	if(type === "App"){
		return (
		<div className="flex justify-center items-center w-full h-screen"> 
		  <FadeLoader 
		    color={"#6F42C1"}
		    loading={true}
		  />
		</div>  
		)
	}

	if(type === "Component"){
		return (
		<div className="flex justify-center items-center w-full h-full"> 
		  <ClipLoader 
		    color={"#6F42C1"}
		    loading={true}
		  />
		</div>  
		)
	}

	if(type === "Action"){
		return (
			<>
			{
				isLoading?
				<div className={`fixed z-50 inset-0 flex items-center justify-center overflow-y-auto backdrop-blur-sm py-8`}>

					<div className="flex flex-col items-center justify-center max-w-[300px] w-full rounded-lg mx-2 my-auto p-4 gap-4">
						<FadeLoader 
							color={"#6F42C1"}
							loading={true}
						/>
					</div>

				</div>
				:
				null
			}
			</>
		)
	}

	return(
		<div className="flex justify-center items-center w-full">	
			<FadeLoader 
			  color={"#6F42C1"}
			  loading={true}
			/>
		</div>
	)

}