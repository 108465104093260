import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";

import VideoPlayerPage from './pages/VideoPlayerPage';

//images
import BENTOHUB_LOGO from './images/BENTOHUB_LOGO.png'

import './App.css';


import VideoPlayer from './components/VideoPlayer';

function App() {

  useEffect(() => {
    const sendData = async () => {
      try {
        const response = await fetch(`${window.server}/log-referrer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ referrer: document.referrer }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const content = await response.text();
        console.log(content);
      } catch (e) {
        console.log('Error sending referrer:', e);
      }
    };

    sendData();
  }, []);


  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="h-screen max-h-screen max-w-screen flex flex-col gap-4 justify-center items-center overflow-hidden">
            <img src={BENTOHUB_LOGO} className="h-16 w-auto "/>
            <h1 className="font-bold text-[24px]">403 Forbidden</h1>
          </div>
        }
      />
      <Route
        path="/:videoId/"
        element={<VideoPlayerPage/>}
      />
      <Route
        path="/:videoId/:controls"
        element={<VideoPlayerPage/>}
      />
    </Routes>
  );
}

export default App;
